.search-order {
    background: #ffffff !important;
    border-radius: 3px !important;
    color: #6d787d !important;
    opacity: 1 !important;
    /* padding: .4rem  0rem !important; */
    border: 1px solid #dddddd !important;
    height: 40px;
    width: 25rem;
    display: flex;
    align-items: center;
    border-radius: 8px !important;
  }
  .Search_input{
    width:100%;
    /* font-size: 6rem !important; */
    /* border:1px solid red; */
  }
  .fs14px{ font-size: 1.4rem;}